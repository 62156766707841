export const MenuItems = [
	{
		display: 'home',
		href: '/#home',
	},
	{
		display: 'about',
		href: '/#about',
	},
  {
		display: 'contact',
		href: '/#contact',
	},
	{
		display: 'photography',
		href: '/photography'
	}
];
