import React, { Component } from 'react';

export default class masthead extends Component {
	render() {
		return (
			<section id='home' className='main'>
				<h1>KENTA YOUNG</h1>
				<h2>Software Engineer and Photographer</h2>
			</section>
		);
	}
}
